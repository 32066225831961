import "animate.css";
//import "flatpickr/dist/flatpickr.css";
import "simplebar-vue/dist/simplebar-vue.js";
import "simplebar-vue/dist/simplebar.min.css";
import "simplebar/dist/simplebar.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import { createApp } from "vue";
import PrimeVue from 'primevue/config';
import Wind from './presets/wind';      
//import VueFlatPickr from "vue-flatpickr-component";
//import VueGoodTablePlugin from "vue-good-table-next";
//import "vue-good-table-next/dist/vue-good-table-next.css";
import VueSweetalert2 from "vue-sweetalert2";
//import VueTippy from "vue-tippy";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
//import VueApexCharts from "vue3-apexcharts";
import VueClickAway from "vue3-click-away";
import App from "./App.vue";

import "./assets/scss/auth.scss";
import "./assets/scss/tailwind.scss";
import router from "./router";
import store from "./store";

import 'primevue/resources/themes/aura-light-green/theme.css'
import "primevue/resources/primevue.min.css"; /* Deprecated */
import "primeicons/primeicons.css";
import "./assets/flags.css";
import ConfirmationService from 'primevue/confirmationservice';
import VueConfetti from 'vue-confetti';
//import VCalendar from "v-calendar";
//import "v-calendar/dist/style.css";
import './assets/css/fonts.css'; // Importe o arquivo CSS aqui
// check localStorage theme for dark light bordered
if (localStorage.theme === "dark") {
  document.body.classList.add("dark");
  store.state.theme = "dark";
  store.state.isDark = true;
} else {
  document.body.classList.add("light");
  store.state.theme = "light";
  store.state.isDark = false;
}


// vue use
createApp(App)
  .use(store)
  .use(VueSweetalert2)
  .use(ConfirmationService)
  .use(Toast, {
    toastClassName: "dashcode-toast",
    bodyClassName: "dashcode-toast-body",
  })
  .use(VueConfetti)
  .use(router)
  .use(VueClickAway)
  .use(PrimeVue, {

    pt: Wind
  })
  //.use(VueTippy)
  //.use(VueFlatPickr)
  //.use(VueGoodTablePlugin)
  //.use(VueApexCharts)
  //.use(VCalendar)
  .mount("#app");
